const MONOCHROMEMODE_CLASS = "is-monochrome";

export default class MonochromeMode {
  currentMode = "";

  setMonochromeMode() {
    document.body.classList.add(MONOCHROMEMODE_CLASS);
    this.currentMode = "monochrome";
  }

  removeMonochromeMode() {
    document.body.classList.remove(MONOCHROMEMODE_CLASS);
    this.currentMode = "";
  }

  toggleMonochromeMode() {
    if (this.currentMode === "monochrome") {
      this.removeMonochromeMode();
    } else {
      this.setMonochromeMode();
    }
  }
}

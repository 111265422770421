<script setup lang="ts">
import {useVModel} from "@vueuse/core";

const props = defineProps({
    isChecked: {
        type: Boolean,
        required: true
    },
    leftLabel: {
        type: String,
        default: ''
    },
    rightLabel: {
        type: String,
        default: ''
    }

})
const emit = defineEmits(['update:isChecked'])
const isChecked = useVModel(props, 'isChecked', emit)
</script>

<template>
    <div class="flex items-start">
        <p class="px-2 text-white text-xs font-semibold">{{leftLabel}}</p>
        <div class="relative inline-block w-8 h-4 rounded-full cursor-pointer">
            <input v-model="isChecked" :id="`switch-component-${leftLabel}`" type="checkbox"
                   class="absolute w-8 h-4 rounded-full appearance-none cursor-pointer peer bg-white/30" />
            <label :for="`switch-component-${leftLabel}`"
                   class="peer-hover:bg-white/60 absolute top-2 left-0.5 h-3.5 w-3.5 -translate-y-2/4 cursor-pointer rounded-full bg-white shadow-md transition-all duration-300 peer-checked:translate-x-3.5">
            </label>
        </div>
        <p class="px-2 text-white text-xs font-semibold">{{rightLabel}}</p>
    </div>
</template>

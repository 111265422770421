import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {Dealer, defaultDealer} from "../util/dealers";

export enum Currency {
    GBP = '£',
    EUR = '€'
}

export const usePreferenceStore = defineStore('preference-store', () => {
    const searchOpen = ref(false)
    const currentDealer: Ref<Dealer> = ref(defaultDealer)
    const setDealer = (dealer: Dealer) => {
        currentDealer.value = dealer
    }
    const currency = ref<Currency>(Currency.GBP)
    const vatIncluded = ref(false)
    const isGbp = computed(() => currency.value === "GBP")
    return {
        currency,
        vatIncluded,
        isGbp,
        currentDealer,
        setDealer,
        searchOpen
    }
}, {
    persist: {
        enabled: true
    },
})

<template>
    <GlobalToasts />
    <NavBar />
    <slot />
    <Footer />
</template>

<script setup lang="ts">
import NavBar from "@/Components/Frontend/Nav/NavBar.vue";
import Footer from "@/Components/Frontend/Global/Footer.vue";
import {onMounted, ref, watch} from "vue";
import { getDealer } from "@/util/dealers"
import {usePreferenceStore} from "@/stores/PreferenceStore";
import '@stripe/stripe-js';
import GlobalToasts from "@/Components/Frontend/Global/GlobalToasts.vue";


const pref = usePreferenceStore()

const currentSubdomain = ref('')

function setTheme(subdomain: string) {
    const dealer = getDealer(subdomain)
    pref.setDealer(dealer)
    Object.entries(dealer.theme).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, value)
    });
}

onMounted(() => {
    currentSubdomain.value = window.location.hostname.split('.')[0]
    setTheme(currentSubdomain.value)
});

watch(currentSubdomain, (newSubdomain) => {
    setTheme(newSubdomain);
});

</script>

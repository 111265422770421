import { defineStore } from "pinia";
import { ref } from "vue";

export interface Toast {
    id: number
    message: string
    type: ToastType
}

export type ToastType = 'success' | 'error'

const toastTimeout = 3000

export const useToastStore = defineStore('toast-store', () => {
    const toasts = ref<Toast[]>([])
    const addToast = (type: ToastType, message: string) => {
        const id = Date.now()
        toasts.value.push({id, type, message})
        setTimeout(() => removeToast(id), toastTimeout)
    }
    const removeToast = (id: number) => {
        toasts.value = toasts.value.filter(toast => toast.id !== id)
    }
    return {
        toasts,
        addToast,
        removeToast
    }
})

<template>
    <div class="flex-col mx-auto z-40">
        <div class="flex items-center justify-center flex-row">
            <form @submit.prevent="searchPage" class="flex flex-col">
                <input v-model="searchText" type="text" class="w-80 border-2 border-frontend-brand px-2 placeholder-white/70 focus:outline-none"
                       :class="isDesktop? 'border-white/50 bg-frontend-brand h-8 text-white focus:border-white border-r-0' : 'h-12 text-frontend-brand'"
                       placeholder="Enter Keyword, VIN, or Registration No.">
                <div v-if="searchOpen" class="flex absolute mt-[34px] mx-auto flex-col items-center">
                    <Link v-for="result in searchResults" :key="result.uuid" :href='`/products/${result.uuid}`' @click="reset" class="flex z-40 w-80 bg-white hover:bg-gray-100 text-black flex-row items-center h-12 gap-2 justify-start border-b border-frontend-brand/20 p-2">
                        <img v-if="result.photo_url" :src="result.photo_url" alt="Machine Image" class="h-10 rounded-md">
                        <p>{{ result.name }}</p>
                    </Link>
                    <div v-if="loading" class="flex z-40 w-80 bg-white hover:bg-gray-100 text-black flex-row items-center h-12 gap-2 justify-start border-b border-frontend-brand/20 p-2">
                        Loading...
                    </div>
                    <div v-if="searchResults.length === 0 && !loading" class="flex z-40 w-80 bg-white hover:bg-gray-100 text-black flex-row items-center h-12 gap-2 justify-start border-b border-frontend-brand/20 p-2">
                        No Results Found
                    </div>
                </div>
            </form>
            <button @click="searchPage" class="  px-4"
                    :class="isDesktop? ' h-8 bg-white text-frontend-brand' : 'h-12 bg-frontend-brand text-white'">
                Search
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue"
import {usePreferenceStore} from "@/stores/PreferenceStore"
import {Link, usePage} from '@inertiajs/vue3';
import {useFetch} from "@vueuse/core";
import {useToastStore} from "@/stores/ToastStore";

defineProps({
    isDesktop: {
        type: Boolean,
        default: false
    }
})

const preferenceStore = usePreferenceStore()
const toasts = useToastStore()

const searchOpen = computed(() => preferenceStore.searchOpen)
const loading = ref(false)

interface SearchResponse {
    searchResults: SearchResult[]
    count: number
    is_machine: boolean
}
interface SearchResult {
    uuid: string
    name: string
    photo_url: string | null
}

const searchResults = ref<SearchResult[]>([])

const searchText = ref();

const reset = () => {
    searchText.value = ''
    preferenceStore.searchOpen = false
}

function searchPage() {
    if (searchText.value.length >= 3) {
        preferenceStore.searchOpen = false
        window.location.href = `/products?search=${searchText.value}`
    } else {
        toasts.addToast('error', 'Search term must be at least 3 characters long')
    }
}

async function search() {
    loading.value = true
    const { data } = await useFetch(`/api/search?search=${searchText.value}`).get()
        .json<SearchResponse>()
    if(data) searchResults.value = data.value.searchResults
    loading.value = false
}
onMounted(async () => {
    searchText.value = ''
    searchResults.value = []
    loading.value = false
})
watch(searchText, async () => {
    if (searchText.value.length >= 3) {
        preferenceStore.searchOpen = true
        await search()
    } else preferenceStore.searchOpen = false
}
)
</script>

<script setup lang="ts">
import {useVModel} from "@vueuse/core";

const props = defineProps({
    isChecked: {
        type: Boolean,
        required: true
    },
    leftLabel: {
        type: String,
        default: ''
    },
    rightLabel: {
        type: String,
        default: ''
    }

})
const emit = defineEmits(['update:isChecked'])
const isChecked = useVModel(props, 'isChecked', emit)
</script>

<template>
    <div class="flex items-center justify-center">
        <p class="px-2 text-frontend-brand text-lg font-semibold">{{leftLabel}}</p>
        <div class="relative inline-block w-16 h-8 rounded-full cursor-pointer">
            <input @click="isChecked = !isChecked" :id="`switch-component-${leftLabel}`" type="checkbox"
                   class="absolute w-16 h-8 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 bg-frontend-brand/30" />
            <label :for="`switch-component-${leftLabel}`"
                   class="peer-hover:bg-frontend-brand absolute top-4 left-0.5 h-7 w-7 -translate-y-2/4 cursor-pointer rounded-full bg-white shadow-md transition-all duration-300 peer-checked:translate-x-8">
            </label>
        </div>
        <p class="px-2 text-frontend-brand text-lg font-semibold">{{rightLabel}}</p>
    </div>
</template>

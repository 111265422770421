const DRAWER_OVERLAY_CLASS = "drawer-overlay";
const DRAWER_CONTENT_CLASS = "drawer-content";

export default class Drawer {
  isActive = false;

  constructor(drawerId) {
    if (!drawerId) {
      throw new TypeError("Error: Drawer not defined");
    }

    this.drawer = document.querySelector(drawerId);

    this.overlay = this.drawer.querySelector(`.${DRAWER_OVERLAY_CLASS}`);
    this.content = this.drawer.querySelector(`.${DRAWER_CONTENT_CLASS}`);

    if (!this.content) {
      throw new TypeError("Error: Drawer content not defined");
    }

    const openBtns = document.querySelectorAll(
      `[data-open="drawer"][data-target="${drawerId}"]`
    );

    const closeBtns = this.drawer.querySelectorAll("[data-close-drawer]");

    openBtns.forEach((node) => {
      node.addEventListener("click", () => this.open());
    });

    closeBtns.forEach((node) => {
      node.addEventListener("click", () => this.close());
    });

    if (this.overlay)
      this.overlay.addEventListener("click", () => this.close());
  }

  open() {
    if (this.isActive) returm;

    if (this.overlay) this.overlay.classList.remove("hidden");
    this.content.classList.remove("hidden");

    this.isActive = true;
  }

  close() {
    if (!this.isActive) returm;

    if (this.overlay) {
      helpers.leaveAnimation(this.overlay, () => {
        this.overlay.classList.add("hidden");
      });
    }
    helpers.leaveAnimation(this.content, () => {
      this.content.classList.add("hidden");
      this.isActive = false;
    });
  }
}

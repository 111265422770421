
export const dealerConfig: Dealer[] = [
    {
        name: 'Quadzilla',
        headline: 'Engineered To Exhilarate',
        hero: 'Quadzilla is the UK\'s largest supplier of on and off-road quads and buggies. Our award winning company has been the market leader for over 10 years.',
        subdomain: '',
        theme: {
            'frontend-brand': '#C22917',
            'frontend-header': '#161c2b',
            'frontend-bg': '#2C3755',
        }
    },
    {
        name: 'CF Moto',
        headline: 'The ATV & UTV Range',
        hero: 'CFMOTO is a ATV and Side x Side Manufacturer. We provide people with quality powersport vehicles such as our CFORCE ATVs & ZFORCE Side x Sides. 763-398-2690',
        subdomain: 'cfmoto',
        theme: {
            'frontend-brand': '#0e6eec',
            'frontend-header': '#01012c',
            'frontend-bg': '#252f5b',
        }
    }
]
export const defaultDealer = dealerConfig[0]

export function getDealer(subdomain: string): Dealer {
    const dealer = dealerConfig.find(dealer => dealer.subdomain === subdomain)
    if (dealer) return dealer
    return dealerConfig[0]
}

export interface Dealer {
    name: string
    headline: string
    hero: string
    subdomain: string
    theme: {
        'frontend-brand': string
        'frontend-header': string
        'frontend-bg': string
    }
}
